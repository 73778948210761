import { SALES_ORGANIZATION_STRUYK_VERWO_INFRA } from '../constants'

export const getConversionRate = ({ unitConversions, unit }) => {
	const { conversionRate } = unitConversions.find(
		(unitConversion) => unitConversion.unit === unit
	)
	return conversionRate
}

export const getQuantityInAnotherUnit = ({
	unitConversions,
	quantity,
	fromUnit,
	toUnit
}) => {
	if (!quantity || quantity === '0') {
		return 0
	}
	const fromUnitConversionRate = getConversionRate({
		unitConversions,
		unit: fromUnit
	})
	const toUnitConversionRate = getConversionRate({
		unitConversions,
		unit: toUnit
	})
	const quantityInAnotherUnit =
		quantity * (fromUnitConversionRate / toUnitConversionRate)
	// Use parseFloat to prevent javascript floating number bug
	return parseFloat(quantityInAnotherUnit.toFixed(3))
}

export const getConvertedQuantity = ({
	salesOrganization,
	quantity,
	unit,
	material,
	availableWeight = Infinity
}) => {
	if (!quantity) {
		return {
			roundingUnit: material.roundingUnit,
			quantityRounded: 0,
			isRounded: false,
			isMaximized: false
		}
	}

	const {
		quantityStock,
		quantityAvailable,
		unitConversions,
		baseUnit,
		shippingPoint,
		materialType,
		weightPerBaseUnit
	} = material
	const quantityInBaseUnit = getQuantityInAnotherUnit({
		unitConversions,
		quantity,
		fromUnit: unit,
		toUnit: baseUnit
	})

	// If shippingPoint is ending on '90', the shippingPoint is a trading location
	const isTradingLocation = shippingPoint.endsWith('90')

	// If the shippingPoint is a trading location, the maximum quantity the available quantity, because
	// trading locations do not have stock. For non-trading locations the stock quantity is the maximum quantity.
	let maxQuantityInBaseUnit = isTradingLocation
		? quantityAvailable
		: quantityStock
	let isLimited = false

	// Modification of a call-off order may not exceed a freight, therefore there is a maximum weight.
	// For new call-off orders the available weight is infinite.
	if (Number.isFinite(availableWeight)) {
		const maxQuantityInBaseUnitForAvailableWeight = Math.floor(
			availableWeight / weightPerBaseUnit
		)
		if (maxQuantityInBaseUnitForAvailableWeight < maxQuantityInBaseUnit) {
			maxQuantityInBaseUnit = maxQuantityInBaseUnitForAvailableWeight
			isLimited = true
		}
	}

	const freightUnit = unitConversions.find(
		(unitConversion) => unitConversion.unit === 'FREIGHT'
	)
	const hasPA = unitConversions.some(
		(unitConversion) => unitConversion.unit === 'PA'
	)
	const hasPCE = unitConversions.some(
		(unitConversion) => unitConversion.unit === 'PCE'
	)
	const hasSET = unitConversions.some(
		(unitConversion) => unitConversion.unit === 'SET'
	)

	let roundingUnit
	if (
		(unit === 'FREIGHT' && hasPA) ||
		unit === 'PA' ||
		(hasPA &&
			freightUnit &&
			quantityInBaseUnit > freightUnit.conversionRate &&
			maxQuantityInBaseUnit > freightUnit.conversionRate)
	) {
		roundingUnit = 'PA'
	} else if (unit === 'FREIGHT' && hasPCE && !hasPA) {
		roundingUnit = 'PCE'
	} else if (
		salesOrganization === SALES_ORGANIZATION_STRUYK_VERWO_INFRA &&
		hasPA &&
		material.roundingUnit === 'LA' &&
		(unit === 'PCE' || unit === 'SET' || unit === 'MTK' || unit === 'TNE')
	) {
		// if unit 'PCE', 'SET', 'MTK' or 'TNE' is selected and default roundingUnit is 'LA', it should be set to 'PA'
		roundingUnit = 'PA'
	} else if (
		hasPCE &&
		freightUnit &&
		quantityInBaseUnit > freightUnit.conversionRate &&
		maxQuantityInBaseUnit > freightUnit.conversionRate
	) {
		roundingUnit = 'PCE'
	} else if (
		hasSET &&
		freightUnit &&
		quantityInBaseUnit > freightUnit.conversionRate &&
		maxQuantityInBaseUnit > freightUnit.conversionRate
	) {
		roundingUnit = 'SET'
	} else {
		roundingUnit = material.roundingUnit
	}

	let quantityRounded = quantityInBaseUnit
	let isRounded = false
	let isMaximized = false

	const maxQuantityInRoundingUnit = getQuantityInAnotherUnit({
		unitConversions,
		quantity: maxQuantityInBaseUnit,
		fromUnit: baseUnit,
		toUnit: roundingUnit
	})
	const quantityInRoundingUnit = getQuantityInAnotherUnit({
		unitConversions,
		quantity,
		fromUnit: unit,
		toUnit: roundingUnit
	})

	if (materialType === 'CUSTOM' && quantityInBaseUnit === quantityStock) {
		// If materialType is CUSTOM and requested quantity is the same as stock, no rounding required, unless unit is freight
		quantityRounded = quantityInBaseUnit
		roundingUnit = unit === 'FREIGHT' ? material.baseUnit : unit
	} else if (
		materialType === 'CUSTOM' &&
		quantityInBaseUnit > quantityStock &&
		!isTradingLocation
	) {
		// If materialType is CUSTOM, requested quantity is more than stock and it's no trading location, call exact stock in baseUnit
		isMaximized = true
		quantityRounded = maxQuantityInBaseUnit
		roundingUnit = material.baseUnit
	} else if (
		quantityInBaseUnit === quantityAvailable &&
		quantityInBaseUnit <= maxQuantityInBaseUnit &&
		!isLimited
	) {
		// All available materials on the order are selected, no rounding required
		quantityRounded = quantityAvailable
		// If the material can't be converted to exact roundingUnit, set baseUnit as roundingUnit
		roundingUnit =
			quantityInRoundingUnit % 1 !== 0 ? material.baseUnit : roundingUnit
	} else if (quantityInBaseUnit === maxQuantityInBaseUnit && !isLimited) {
		quantityRounded = getQuantityInAnotherUnit({
			unitConversions,
			quantity: Math.floor(quantityInRoundingUnit),
			fromUnit: roundingUnit,
			toUnit: baseUnit
		})
	} else if (quantityInRoundingUnit > maxQuantityInRoundingUnit) {
		// Requested quantity exceeds stock, round down to maximum quantity in rounding unit
		isMaximized = true
		quantityRounded = getQuantityInAnotherUnit({
			unitConversions,
			quantity: Math.floor(maxQuantityInRoundingUnit),
			fromUnit: roundingUnit,
			toUnit: baseUnit
		})
	} else if (
		Math.floor(quantityInRoundingUnit) > 0 &&
		Math.floor(quantityInRoundingUnit) === Math.floor(maxQuantityInRoundingUnit)
	) {
		// Maximum rounding units is reached (but still loose base units available), round down to maximum rounding units
		quantityRounded = getQuantityInAnotherUnit({
			unitConversions,
			quantity: Math.floor(quantityInRoundingUnit),
			fromUnit: roundingUnit,
			toUnit: baseUnit
		})
	} else if (
		roundingUnit !== unit &&
		Math.floor(quantityInRoundingUnit) === 0 &&
		Math.floor(maxQuantityInRoundingUnit) === 0
	) {
		// If there is no full roundingUnit available, round down to 0
		quantityRounded = getQuantityInAnotherUnit({
			unitConversions,
			quantity: Math.floor(quantityInRoundingUnit),
			fromUnit: roundingUnit,
			toUnit: baseUnit
		})
	} else if (roundingUnit !== unit) {
		// The FREIGHT unit rounds down and all others round up (because if the freight unit rounds up, it will exceed 1 freight)
		quantityRounded = getQuantityInAnotherUnit({
			unitConversions,
			quantity:
				unit === 'FREIGHT'
					? Math.floor(quantityInRoundingUnit)
					: Math.ceil(quantityInRoundingUnit),
			fromUnit: roundingUnit,
			toUnit: baseUnit
		})
	}

	// TODO
	// isRounded should be changed to isConverted in the upcoming change.
	// the same goes for quantityRounded to quantityConverted

	if (unit !== roundingUnit || quantityInBaseUnit !== quantityRounded) {
		isRounded = true
	}

	return { roundingUnit, quantityRounded, isRounded, isMaximized }
}

export const getQuantityToComplement = ({
	newCallOffMaterial,
	material,
	weightLimitPerTruck,
	totalWeight
}) => {
	const {
		baseUnit,
		unitConversions,
		weightPerBaseUnit,
		quantityStock,
		quantityAvailable
	} = material
	const isTradingLocation = material.shippingPoint.endsWith('90')

	// Calculate the amount of PAK units which can be added
	const quantityInBaseUnit = getQuantityInAnotherUnit({
		unitConversions,
		quantity: newCallOffMaterial.quantity,
		fromUnit: newCallOffMaterial.unit,
		toUnit: material.baseUnit
	})
	const quantityWeight = quantityInBaseUnit * weightPerBaseUnit
	const quantityStockWeight = quantityStock * weightPerBaseUnit
	const quantityAvailableWeight = quantityAvailable * weightPerBaseUnit
	const availableWeight = weightLimitPerTruck - totalWeight + quantityWeight
	const conversionRatePAK = getConversionRate({ unitConversions, unit: 'PA' })
	const maximumQuantityInPAK = Math.floor(
		availableWeight /
			(baseUnit === 'PA'
				? weightPerBaseUnit
				: weightPerBaseUnit * conversionRatePAK)
	)
	const quantityStockInPAK = Math.floor(
		quantityStockWeight /
			(baseUnit === 'PA'
				? weightPerBaseUnit
				: weightPerBaseUnit * conversionRatePAK)
	)
	const quantityAvailableInPAK = Math.floor(
		quantityAvailableWeight /
			(baseUnit === 'PA'
				? weightPerBaseUnit
				: weightPerBaseUnit * conversionRatePAK)
	)

	let complementedQuantityInPAK
	if (isTradingLocation) {
		complementedQuantityInPAK =
			quantityAvailableInPAK < maximumQuantityInPAK
				? quantityAvailableInPAK
				: maximumQuantityInPAK
	} else {
		complementedQuantityInPAK =
			quantityStockInPAK < maximumQuantityInPAK
				? quantityStockInPAK
				: maximumQuantityInPAK
	}

	return complementedQuantityInPAK
}
