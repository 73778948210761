import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import numeral from 'numeral'
import i18next, { t } from 'i18next'
import {
	Grid,
	Typography,
	Dialog,
	MenuItem,
	Button,
	TextField,
	SvgIcon,
	Box
} from '@mui/material'

import { lightGreen, amber } from '@mui/material/colors'
import { CircleFas, ExclamationTriangleFas } from '@oliverit/react-fontawesome'

import {
	getQuantityInAnotherUnit,
	getConvertedQuantity
} from '../../../../../utils/materials'
import { ORDER_TYPE_SALES_ORDER } from '../../../../../utils/constants'

const sx = {
	alignRight: {
		textAlign: 'right'
	},
	dialogContent: {
		padding: (theme) => theme.spacing(2)
	},
	spacingBottom: {
		marginBottom: (theme) => theme.spacing(2)
	},
	stockIndicator: {
		display: 'flex',
		alignItems: 'center'
	},
	stockText: {
		marginLeft: (theme) => theme.spacing()
	},
	stockAvailable: {
		color: lightGreen.A700
	},
	stockPartiallyAvailable: {
		color: amber.A700
	},
	stockUnavailable: {
		color: (theme) => theme.palette.error.dark
	},
	rightContent: {
		backgroundColor: (theme) => theme.palette.grey[100],
		height: '100%'
	},
	labelTextColor: {
		color: (theme) => theme.palette.grey[700]
	},
	warning: {
		color: amber.A700
	}
}

class AddMaterialDialog extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		width: PropTypes.string.isRequired,
		open: PropTypes.bool.isRequired,
		cancel: PropTypes.func.isRequired,
		confirm: PropTypes.func.isRequired,
		material: PropTypes.object.isRequired,
		newCallOffMaterial: PropTypes.object.isRequired,
		totalWeight: PropTypes.number.isRequired,
		weightLimitPerTruck: PropTypes.number.isRequired,
		salesOrganization: PropTypes.string.isRequired,
		orderType: PropTypes.string.isRequired,
		changeQuantity: PropTypes.func.isRequired,
		changeUnit: PropTypes.func.isRequired
	}

	getAvailableWeight = () => {
		const {
			orderType,
			newCallOffMaterial,
			material,
			weightLimitPerTruck,
			totalWeight
		} = this.props
		if (orderType === ORDER_TYPE_SALES_ORDER) {
			return Infinity
		}

		// The user is editting an existing call-off order
		// There is a maximum weight (because existing call-off orders may not be splitted)
		const quantityInBaseUnit = getQuantityInAnotherUnit({
			unitConversions: material.unitConversions,
			quantity: newCallOffMaterial.quantity,
			fromUnit: newCallOffMaterial.unit,
			toUnit: material.baseUnit
		})
		const quantityWeight = quantityInBaseUnit * material.weightPerBaseUnit
		return Math.floor(weightLimitPerTruck - totalWeight + quantityWeight)
	}

	state = {
		quantity: this.props.newCallOffMaterial.quantity,
		unit: this.props.newCallOffMaterial.unit,
		isQuantityNumberValid: true,
		...getConvertedQuantity({
			salesOrganization: this.props.salesOrganization,
			quantity: this.props.newCallOffMaterial.quantity,
			unit: this.props.newCallOffMaterial.unit,
			material: this.props.material,
			availableWeight: this.getAvailableWeight()
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { quantity, unit } = this.state
		const { salesOrganization, material } = this.props

		if (prevState.quantity !== quantity || prevState.unit !== unit) {
			// Quantity or unit has changed, validate quantity
			const isQuantityNumberValid =
				!quantity || /^[0-9]([0-9]{0,15}$)/.test(quantity.toString())

			if (!isQuantityNumberValid) {
				// Quantity has an invalid number
				this.setState({
					isQuantityNumberValid,
					quantityRounded: 0,
					isRounded: false,
					isMaximized: false
				})
			} else if (!quantity) {
				// Quantity is set to 0 or empty
				this.setState({
					quantityRounded: 0,
					isRounded: false,
					isMaximized: false
				})
			} else {
				// Quantity has a correct number
				const availableWeight = this.getAvailableWeight()
				this.setState({
					isQuantityNumberValid,
					...getConvertedQuantity({
						salesOrganization,
						quantity,
						unit,
						material,
						availableWeight
					})
				})
			}
		}
	}

	handleOrderQuantityChange = (event) => {
		const quantity = event.target.value
		// Allow a maximum of 15 characters because of SAP number limit
		if (quantity.length <= 15) {
			this.setState({ quantity })
		}
	}

	handleUnitTypeChange = (event) => {
		const unit = event.target.value
		this.setState({ unit })
	}

	handleCancelClick = (event) => {
		event.stopPropagation()

		const { cancel } = this.props
		cancel()
	}

	handleConfirmClick = (event) => {
		event.stopPropagation()

		const { confirm, material } = this.props
		const { unitConversions, baseUnit } = material
		const { quantity, unit, roundingUnit, quantityRounded, isRounded } =
			this.state
		if (isRounded) {
			const quantityInRoundingUnit = getQuantityInAnotherUnit({
				unitConversions,
				quantity: quantityRounded,
				fromUnit: baseUnit,
				toUnit: roundingUnit
			})
			confirm({ quantity: quantityInRoundingUnit, unit: roundingUnit })
		} else {
			confirm({ quantity, unit })
		}
	}

	handleKeyPressQuantity = (event) => {
		const { isQuantityNumberValid } = this.state
		if (event.key === 'Enter' && isQuantityNumberValid) {
			this.handleOrderQuantityChange(event)
			this.handleConfirmClick(event)
		}
	}

	render() {
		const { material, open, width } = this.props
		const {
			quantity,
			unit,
			roundingUnit,
			isQuantityNumberValid,
			quantityRounded,
			isRounded,
			isMaximized
		} = this.state

		const sameBaseAndRoundingUnit =
			material.baseUnit === material.roundingUnit ||
			roundingUnit === material.baseUnit
		const quantityInRoundingUnit = getQuantityInAnotherUnit({
			quantity: quantityRounded,
			fromUnit: material.baseUnit,
			toUnit: roundingUnit,
			unitConversions: material.unitConversions
		})
		const conversionRoundingUnit = material.unitConversions.find(
			(unitConversion) => unitConversion.unit === roundingUnit
		)
		const quantityInBaseUnit = getQuantityInAnotherUnit({
			quantity,
			fromUnit: unit,
			toUnit: material.baseUnit,
			unitConversions: material.unitConversions
		})
		const conversionBaseUnit = material.unitConversions.find(
			(unitConversion) => unitConversion.unit === material.baseUnit
		)
		const calculatedQuantity =
			quantityRounded === undefined ? quantity : quantityRounded
		const isSmallScreen = width === 'xs'
		const showMoreThanAvailableWarning =
			material.materialType !== 'CUSTOM' &&
			quantityInBaseUnit > material.quantityAvailable

		let helperText
		let errorText
		if (!isQuantityNumberValid) {
			errorText = t('app:Materials.invalidQuantityNumber')
		} else if (sameBaseAndRoundingUnit && isRounded && isMaximized) {
			helperText = t('app:Materials.quantityRoundedAndMaximizedInBaseUnit', {
				quantityInBaseUnit: numeral(quantityRounded).format('0,0.[000]'),
				baseUnit: conversionBaseUnit.descriptions.short[i18next.language]
			})
		} else if (sameBaseAndRoundingUnit && isRounded) {
			helperText = t('app:Materials.quantityRoundedInBaseUnit', {
				quantityInBaseUnit: numeral(quantityRounded).format('0,0.[000]'),
				baseUnit: conversionBaseUnit.descriptions.short[i18next.language]
			})
		} else if (isRounded && isMaximized) {
			// The material is rounded up to the rounding unit, add helper text to inform the user
			helperText = t(
				'app:Materials.quantityRoundedAndMaximizedInRoundingUnit',
				{
					quantityInRoundingUnit: numeral(quantityInRoundingUnit).format(
						'0,0.[000]'
					),
					roundingUnit:
						conversionRoundingUnit.descriptions.short[i18next.language],
					quantityInBaseUnit: numeral(quantityRounded).format('0,0.[000]'),
					baseUnit: conversionBaseUnit.descriptions.short[i18next.language]
				}
			)
		} else if (isRounded) {
			// The material is rounded up to the rounding unit, add helper text to inform the user
			helperText = t('app:Materials.quantityRoundedInRoundingUnit', {
				quantityInRoundingUnit: numeral(quantityInRoundingUnit).format(
					'0,0.[000]'
				),
				roundingUnit:
					conversionRoundingUnit.descriptions.short[i18next.language],
				quantityInBaseUnit: numeral(quantityRounded).format('0,0.[000]'),
				baseUnit: conversionBaseUnit.descriptions.short[i18next.language]
			})
		}

		const warningIcon = (
			<SvgIcon fontSize="small" sx={sx.warning}>
				<ExclamationTriangleFas />
			</SvgIcon>
		)

		let roundedAmount
		if (sameBaseAndRoundingUnit) {
			roundedAmount = `${numeral(quantityRounded).format('0,0.[000]')} ${
				conversionBaseUnit.descriptions.long[i18next.language]
			}`
		} else {
			roundedAmount = `${numeral(quantityInRoundingUnit).format('0,0.[000]')} ${
				conversionRoundingUnit.descriptions.long[i18next.language]
			} (${numeral(quantityRounded).format('0,0.[000]')} ${
				conversionBaseUnit.descriptions.long[i18next.language]
			})`
		}

		let stockIndicator
		if (material.stockAvailability === 'AVAILABLE') {
			stockIndicator = (
				<SvgIcon fontSize="inherit" sx={sx.stockAvailable}>
					<CircleFas />
				</SvgIcon>
			)
		} else if (material.stockAvailability === 'PARTIALLY_AVAILABLE') {
			stockIndicator = (
				<SvgIcon fontSize="inherit" sx={sx.stockPartiallyAvailable}>
					<CircleFas />
				</SvgIcon>
			)
		} else {
			stockIndicator = (
				<SvgIcon fontSize="inherit" sx={sx.stockUnavailable}>
					<CircleFas />
				</SvgIcon>
			)
		}

		let moreThenAvailableWarning
		if (showMoreThanAvailableWarning) {
			moreThenAvailableWarning = (
				<Grid container spacing={1} direction="row" alignItems="flex-start">
					<Grid item xs={1}>
						{warningIcon}
					</Grid>
					<Grid item xs={11}>
						<Typography variant="body2">
							{t('app:Materials.moreThenAvailable')}
						</Typography>
					</Grid>
				</Grid>
			)
		}

		let helperWarning
		if (helperText) {
			helperWarning = (
				<Grid container spacing={1} direction="row" alignItems="flex-start">
					<Grid item xs={1}>
						{warningIcon}
					</Grid>
					<Grid item xs={11}>
						<Typography variant="body2" paragraph>
							{helperText}
						</Typography>
					</Grid>
				</Grid>
			)
		}

		return (
			<Dialog
				open={open}
				onClose={this.handleCancelClick}
				maxWidth="md"
				fullWidth
				fullScreen={isSmallScreen}
			>
				<Grid container>
					<Grid
						item
						container
						sm={6}
						xs={12}
						direction="column"
						justifyContent="space-between"
					>
						<Grid item container spacing={2} sx={sx.dialogContent}>
							<Grid item xs={12}>
								<Typography variant="h5" paragraph>
									{t('app:Materials.desiredQuantity')}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									variant="outlined"
									placeholder="0"
									value={quantity}
									error={!isQuantityNumberValid}
									label={t('app:Materials.quantity')}
									helperText={errorText}
									inputProps={{ pattern: '[0-9]*', inputMode: 'numeric' }}
									onChange={this.handleOrderQuantityChange}
									onKeyPress={this.handleKeyPressQuantity}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									select
									variant="outlined"
									label={t('app:Materials.unit')}
									value={unit}
									onChange={this.handleUnitTypeChange}
								>
									{material.unitConversions.map((unitConversion, index) => (
										<MenuItem key={index} value={unitConversion.unit}>
											{unitConversion.descriptions.long[i18next.language]}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item>
								{moreThenAvailableWarning}
								{helperWarning}
								{isRounded && (
									<Typography variant="h5">
										{t('app:Materials.actualQuantity')}
									</Typography>
								)}
								{isRounded && (
									<Typography variant="h5" color="primary">
										{roundedAmount}
									</Typography>
								)}
							</Grid>
						</Grid>
						<Grid item container sx={sx.dialogContent}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Button
										fullWidth
										elevation={0}
										onClick={this.handleCancelClick}
										color="default"
									>
										{t('app:Generic.cancel')}
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										fullWidth
										variant="contained"
										elevation={0}
										color="primary"
										disabled={!isQuantityNumberValid}
										onClick={this.handleConfirmClick}
									>
										{t('app:Generic.accept')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item sm={6} xs={12}>
						<Box sx={{ ...sx.dialogContent, ...sx.rightContent }}>
							<Typography variant="h6">{t('app:Materials.article')}</Typography>
							<Typography variant="body2" paragraph>
								{material.materialNumber} &middot;{' '}
								{material.descriptions[i18next.language]}
							</Typography>
							<Grid container spacing={2} sx={sx.spacingBottom}>
								<Grid item xs={6}>
									<Typography variant="h6">
										{t('app:Materials.shippingPointDescription')}
									</Typography>
									<Typography variant="body2">
										{material.shippingPointDescription}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h6">
										{material.materialType === 'CUSTOM'
											? t('app:Materials.customerStock')
											: t('app:Materials.stockIndication')}
									</Typography>
									<Box sx={sx.stockIndicator}>
										{stockIndicator}
										<Typography variant="body2" sx={sx.stockText}>
											{material.materialType === 'CUSTOM'
												? numeral(material.quantityStock).format(0, 0)
												: undefined}
										</Typography>
									</Box>
								</Grid>
							</Grid>
							<Typography variant="h6">
								{t('app:Materials.yetToCall')}
							</Typography>
							<Grid container spacing={2} sx={sx.spacingBottom}>
								<Grid item sx={sx.alignRight}>
									<Typography variant="body2">
										{numeral(material.quantityAvailable).format(0, 0)}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2" sx={sx.labelTextColor}>
										{t('app:Generic.Units.pieces')}
									</Typography>
								</Grid>
							</Grid>
							<Typography variant="h6">
								{t('app:Materials.quantityRoundedTitle')}
							</Typography>
							<Grid container spacing={2} sx={sx.spacingBottom}>
								<Grid item sx={sx.alignRight}>
									{material.unitConversions.map((unitConversion, index) => (
										<Typography key={index} variant="body2">
											{numeral(
												getQuantityInAnotherUnit({
													unitConversions: material.unitConversions,
													quantity: calculatedQuantity,
													fromUnit: material.baseUnit,
													toUnit: unitConversion.unit
												})
											).format('0,0.[000]')}
										</Typography>
									))}
								</Grid>
								<Grid item>
									{material.unitConversions.map((unitConversion, index) => (
										<Typography
											key={index}
											variant="body2"
											sx={sx.labelTextColor}
										>
											{unitConversion.descriptions.long[i18next.language]}
										</Typography>
									))}
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Dialog>
		)
	}
}

export default compose()(AddMaterialDialog)
